export interface AppConfig {
  language: Language;
  dark_theme: boolean;
}

export interface AppConfigWithNotifications extends AppConfig {
  notifications: boolean;
  notificationsLogoUrl: string;
  notificationsAudioUrl: string;
}

export enum Language {
  EN = 'en',
  ES = 'es',
  AR = 'ar',
  CN = 'cn',
  RU = 'ru',
  FR = 'fr',
  HI = 'hi',
  TR = 'tr',
  PT = 'pt',
}

export enum LanguageNavigation {
  ar = 'ar',
  es = 'es-ES',
  en = 'en-US',
  cn = 'zh-CN',
  ru = 'ru',
  fr = 'fr',
  hi = 'hi',
  tr = 'tr',
  pt = 'pt-PT',
}
