import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsPanelService {
  private readonly _showSettingsPanel$ = new Subject<boolean>();
  public showSettingsPanel$ = this._showSettingsPanel$.asObservable();

  public showSettingsPanel() {
    this._showSettingsPanel$.next(true);
  }

  public hideSettingsPanel() {
    this._showSettingsPanel$.next(false);
  }
}
