import type { Location } from '@angular/common';
import type { Renderer2, RendererFactory2, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import type { TranslateService } from '@ngx-translate/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, filter, map, take, tap } from 'rxjs';
import { LocalStorageUtil } from '@dextools/utils';
import type { SeoService } from '../seo/seo.service';
import type { AppConfig } from '../../models/config.model';
import { Language } from '../../models/config.model';
import { Theme } from '../../models/theme.model';

/**
 * Service used to emit whenever the App settings change
 */
export abstract class SettingsService<ConfigType extends AppConfig> {
  public abstract readonly _localStorageConfigKey: string;
  public abstract readonly _defaultConfigValue: Partial<ConfigType>;

  private _currentConfigValue: Partial<ConfigType> = {};
  private readonly _onConfigChange = new BehaviorSubject<Partial<ConfigType>>({});

  public onThemeChange$ = this.getConfigChanged$('dark_theme').pipe(map((value) => (value ? Theme.Dark : Theme.Light)));

  // IMPORTANT: these properties are protected so that they can be accessed by the child class
  /* eslint-disable @typescript-eslint/naming-convention */
  protected _renderer!: Renderer2;
  protected _document!: Document;
  protected _language!: Language;
  /* eslint-enable @typescript-eslint/naming-convention */

  public get language(): Language {
    return this._language;
  }

  public get defaultConfigValue(): Partial<ConfigType> {
    return this._defaultConfigValue;
  }

  private get localStorageConfigKey(): string {
    return this._localStorageConfigKey;
  }

  protected constructor(
    private readonly _location: Location,
    private _translate: TranslateService,
    private readonly _seoService: SeoService,
    private readonly _rendererFactory: RendererFactory2,
    private readonly _byPassLocalStorage = false,
  ) {}

  /**
   * Initialization logic necessary to change App settings.
   * Loads to memory the config from localStorage (if any).
   *
   * IMPORTANT: This method must be called as soon as possible when the app starts (i.e. AppComponent's constructor)
   *
   * @param document - DOM Document element
   * @param appRenderer - Application renderer used to perform DOM manipulation. This is needed because Angular services cannot
   * inject this renderer, only components. In case it's not provided then a custom renderer wil be created instead.
   */
  public initialize(document: Document, appRenderer?: Renderer2): void {
    this._document = document;
    // If no rendered provided, then we should create a custom one. See https://www.techiediaries.com/angular-10-renderer2-services-rendererfactory2-dynamic-div/
    this._renderer = appRenderer ?? this._rendererFactory.createRenderer(null, null);
    this._language = this.defaultConfigValue.language as Language;
    this._translate.setDefaultLang(this._language);

    const storedConfig = this._getConfigFromStorage();
    this._currentConfigValue = { ...this.defaultConfigValue, ...storedConfig };
    if (!this._byPassLocalStorage) {
      LocalStorageUtil.set(this.localStorageConfigKey, {
        ...(this._currentConfigValue as Partial<ConfigType> extends string ? never : Partial<ConfigType>),
      });
    }
    /** Set local storage language if exist */
    this._language = this._currentConfigValue.language as Language;
    this.changeConfig(this._currentConfigValue);
  }

  public changeConfig(value: Partial<ConfigType>): void {
    this._currentConfigValue = this._mergeWithStoredConfig(value);
    this._changeConfig(value);
    this._storeConfig(this._currentConfigValue);
  }

  /**
   * Sets a temporal config that will be used until the next time the app is loaded.
   *
   * @param config - Config to be used temporarily.
   */
  public setTemporalConfig(config: Partial<ConfigType>): void {
    this._currentConfigValue = this._mergeWithStoredConfig(config);
    this._changeConfig(this._currentConfigValue);
  }

  public getConfigChanged$<KeyOfConfig extends keyof ConfigType>(property: KeyOfConfig): Observable<NonNullable<ConfigType>[KeyOfConfig]> {
    return this._onConfigChange.asObservable().pipe(
      map((config) => config[property]),
      filter((value): value is NonNullable<ConfigType>[KeyOfConfig] => value != null),
    );
  }

  /**
   * Returns a Signal of the config property requested.
   *
   * @param property - Property of the config to be returned.
   * @returns Signal of the config property requested.
   */
  public getConfig<KeyOfConfig extends keyof ConfigType>(property: KeyOfConfig): Signal<NonNullable<ConfigType>[KeyOfConfig] | undefined> {
    return toSignal(this.getConfigChanged$(property));
  }

  /**
   * For those apps developed to serve as a micro-frontend, the Translate Service that actually contains the translations of the app
   * is defined in the child module (remote entry module). However, this Settings Service is instantiated at the App Module (root),
   * therefore it can only get the Translate Service that is also instantiated at the App Module.
   *
   * In such situation, this method allows to instruct the Settings Service to use the correct instance of the Translate Service which
   * should be used to effectively change the app's language.
   *
   * @param newServiceInstance - Translate Service instance that should be used to change the app's language
   */
  public overrideTranslateService(newServiceInstance: TranslateService): void {
    this._translate = newServiceInstance;
  }

  /**
   * Changes the app config and emits the new config to the subscribers.
   *
   * @param config - New config to be set.
   */
  private _changeConfig(config: Partial<ConfigType>) {
    if (config.dark_theme != null) {
      this._changeTheme(config.dark_theme);
    }
    if (config.language != null) {
      // Emit onConfigChange when the language is changed and the translations file has been downloaded
      this._translate.onLangChange
        .pipe(
          take(1),
          tap(() => {
            this._onConfigChange.next(this._currentConfigValue);
          }),
        )
        .subscribe();

      this._changeLanguage(config.language);
      return;
    }
    this._onConfigChange.next(this._currentConfigValue);
  }

  /**
   * Stores the config in the local storage.
   *
   * @param config - Config to be stored.
   */
  private _storeConfig(config: Partial<ConfigType>) {
    if (this._byPassLocalStorage) {
      return;
    }
    LocalStorageUtil.setString(this.localStorageConfigKey, JSON.stringify({ ...config }));
  }

  private _getConfigFromStorage(): Partial<ConfigType> | null {
    if (this._byPassLocalStorage) {
      return this._currentConfigValue;
    }
    return JSON.parse(LocalStorageUtil.getString(this.localStorageConfigKey) as string) || null;
  }

  private _changeTheme(isDarkTheme: boolean) {
    if (isDarkTheme) {
      this._renderer.addClass(this._document.body, 'dark-theme');
    } else {
      this._renderer.removeClass(this._document.body, 'dark-theme');
    }
  }

  private _changeLanguage(language: Language) {
    /** Replace path url only when doing a manual language change. Prevent other parts of the url from being replaced other than the language */
    if (this._language !== language) {
      this._location.replaceState(this._location.path(true).replace(`/${this._language}`, `/${language}`));
    }
    this._language = language;
    this._translate.use(language);
    this._document.querySelectorAll('html')[0].setAttribute('lang', language); // Set HTML language
    this._setMetadataSEO(language);
  }

  private _mergeWithStoredConfig(value: Partial<ConfigType>) {
    let configApp: Partial<ConfigType> = this._getConfigFromStorage() ?? {};
    const keys = Object.keys(value) as (keyof ConfigType)[];
    const values = Object.values(value);

    for (const [i, key] of keys.entries()) {
      const valueKey = values[i];
      if (typeof value === 'object' && value != null) {
        // eslint-disable-next-line unicorn/prefer-ternary
        if (typeof valueKey === 'object' && valueKey != null) {
          configApp = {
            ...configApp,
            [key]: {
              ...configApp[key],
              ...valueKey,
            },
          };
        } else {
          configApp = { ...configApp, [key]: valueKey };
        }
      }
    }
    return configApp;
  }

  private _setMetadataSEO(language: Language) {
    this._seoService.removeGeneralMetaTag('robots');
    if (language !== Language.EN) {
      this._seoService.setGeneralMetaTags({ robots: 'noindex' });
    }
    // If the app is not running in production, we don't want to index it
    if (process.env['NX_PUBLIC_APP_ENVIRONMENT'] !== 'prod') {
      this._seoService.setGeneralMetaTags({ robots: 'noindex, nofollow' });
    }
  }

  /**
   * Gets the current config of the app.
   *
   * @returns The current config.
   */
  public getFullConfig(): Partial<ConfigType> {
    return this._currentConfigValue;
  }
}
